import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { enquireScreen } from 'enquire-js';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Home/Nav3';
import Footer0 from './Home/Footer0';
import Footer1 from './Home/Footer1';
import Home from './Home';
import Why from './Why';
import Service from './Service';
import Careers from './Careers';
import About from './About';

import {
  Nav30DataSource,
  Footer01DataSource,
  Footer10DataSource
} from './Home/data.source.js';

const Main = (props)=>{
  const [isMobile, setIsMobile] = useState(false);

  useEffect(()=>{
    enquireScreen((b) => {
      setIsMobile(!!b);
    });
  }, []);

  return <Router>
    <div className="website">
      <Header dataSource={Nav30DataSource} isMobile={isMobile} />
      <div className="page">
        <Routes>
          <Route exact path="/page/aboutus" element={<About />} />
          <Route exact path="/page/careers" element={<Careers />} />
          <Route exact path="/page/service" element={<Service />} />
          <Route exact path="/page/why" element={<Why />} />
          <Route exact path="/page/home" element={<Home />} />
          <Route exact path="*" element={<Home />} />
        </Routes>
      </div>
      <Footer1 dataSource={Footer10DataSource} isMobile={isMobile} />
      <Footer0 dataSource={Footer01DataSource} isMobile={isMobile} />
    </div>
  </Router>
}

ReactDOM.render(
    <Main />
  ,
  document.getElementById('root')
);
