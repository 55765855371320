import React from 'react';
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 bg-image' },
        title: {
          className: 'banner2-title kx7eraslrr8-editor_css',
          children: (
            <span>
              <p>About</p>
            </span>
          ),
        },
        title_right: {
          className: 'banner2-title kx7eraslrr8-editor_css',
          children: (
            <span>
              <p>&nbsp;</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content kx7erc3r78q-editor_css',
          children: (
            <span>
              <p>Everywhere payments happen, we’re there.</p>
            </span>
          ),
        },
        content_right: {
          className: 'banner2-content kx7erc3r78q-editor_css',
          children: (
            <span>
              <p>Our mission is to build the bridge that provides people in every part of the world access to a fairer and more equitable financial system.</p>
              <p>We believe that transparency and regulation is the key to unlocking the full potential of digital assets for the world.</p>
            </span>
          )
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span style={{background:'#fff', color:'rgb(0, 115, 209)'}}>WE’RE INDUSTRY LEADERS</span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <p>
                  Our payments infrastructure is compliant with the highest
                  security and compliance standards.&nbsp;
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              Our suite of products includes our widget, white-label gateways,
              and customised services for digital asset platforms.
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
