import React from 'react';
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>WHY FINLUX?</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p style={{color:'#0073d1', fontSize:18}}>Because we are the best at what we do.</p>
          <p>
            <br />
          </p>
          <p>
            We provide the widest choice of payment options, both locally and
            globally. Giving your users local currencies and lower rates, so
            they convert more.
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
				"name": "title",
				"className": "feature7-title-h1",
				"children": <span><span style={{background:'#f7f9fc', color:'rgb(0, 115, 209)'}}>HOW WE CAN HELP YOU</span></span>
			},
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>
              We remove risks from your payment process and enable your users to
              buy &amp; sell crypto with ease.
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Highest Conversions</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>We offer industry-leading conversion rates.</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [

            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Fast &amp; Secure</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>Pay and receive coins within minutes</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [

            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Easy to Use</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>User-friendly and straightforward interface</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [

            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Expert Support</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>Customer support available 24/7</p>
                  <p>&nbsp;</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
