import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children: require('../assets/finlux_logo.png').default,
    href:'#/page/home'
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: '#/page/why',
        className: 'header3-item',
        children: {
          href: '#/page/why',
          children: [
            {
              children: (
                <span>
                  <p>WHY FINLUX</p>
                </span>
              ),
              name: 'text',
            },
          ],
        }
      },
      {
        name: '#/page/service',
        className: 'header3-item',
        children: {
          href: '#/page/service',
          children: [
            {
              children: (
                <span>
                  <p>SERVICE</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: '#/page/careers',
        className: 'header3-item',
        children: {
          href: '#/page/careers',
          children: [
            {
              children: (
                <span>
                  <p>CAREERS</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: '#/page/aboutus',
        className: 'header3-item',
        children: {
          href: '#/page/aboutus',
          children: [
            {
              children: (
                <span>
                  <p>ABOUT US</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: {
          className: 'banner2-text-wrapper kwvrc6vwl2-editor_css',
        },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title kwvrbnudc08-editor_css',
          children: (
            <span>
              <p>Start Your Digital Currency Journey on Finlux</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <p>You can now accept digital currencies with Finlux</p>
            </span>
          ),
        },
        button: { className: 'banner2-button', children: <a onClick={()=>{window.location.hash = '/page/service'}}>Learn More</a> },
      },
    ],
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span style={{background:'#fff', color:'rgb(0, 115, 209)'}}>WHY FINLUX IS THE BEST</span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper kwvro0vbej-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../assets/icon2.png').default
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Support multiple currencies</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../assets/icon1.png').default,
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Fast &amp; Secure</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../assets/icon3.png').default,
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>24/7 support</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon kwvs0uamn37-editor_css',
            children:
              require('../assets/icon4.png').default
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Founded 2020<br />
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
    ],
    gutter: {},
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 kwvsjt0d5gt-editor_css',
  },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      require('../assets/feature_01.jpg').default
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>The Finlux Vision and Mission</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Finlux’s vision and mission is to build the bridge that provides
              people in every part of the world access to a fairer and more
              equitable financial system.
            </p>
            <p>
              We believe that transparency and regulation is the key to
              unlocking the full potential of digital assets for the world.
            </p>
          </span>
        ),
        className: 'pricing0-content kwvsivycal-editor_css',
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper kwvsljizpq-editor_css',
  },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page kwvsl8yq3o-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <img src={require('../assets/finlux_logo.png').default} width="100%" alt="img" />,
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>
                      1705/601 Little Longsdale Street, Melbourne, VIC, 3000,
                      Australia.
                    </p>
                    <p>Finlux is a digital currency exchange provider.</p>
                  </span>
                </span>
              ),
              className: 'kwvslryy8v9-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>THE FINE PRINT</p>
            </span>
          ),
          className: 'kwvslpe5d0a-editor_css',
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <p>Terms of use</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>Privacy policy</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p>AML/CTF policy</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '#',
              children: (
                <span>
                  <p>Cookie policy</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>Contact Us</p>
              </span>
            </span>
          ),
          className: 'kwvsnzfs0wa-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: 'mailto:payment@finlux.cc',
              name: 'link0',
              children: (
                <span>
                  <p>payment@finlux.cc</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  }
};
export const Footer01DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2021 FINLUX&nbsp;All Rights Reserved</span>
      </span>
    ),
  },
};
