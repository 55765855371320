import React from 'react';
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kx7d149ybma-editor_css',
  },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Fiat Deposit and Withdrawal</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>
                Customers seamlessly verify, pay and receive coins within
                minutes, without ever leaving your platform.
              </p>
              <p>
                This service will allow users in Australia to deposit and
                withdraw Australian dollars (AUD) , and use the deposit to buy
                cryptocurrencies, such as USDT, BTC, and ETH.
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  img: {
    children: require('../assets/service.jpeg').default,
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>Enter Amount</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                Enter the amount you wish to buy (fiat or crypto), followed by
                your email address and mobile.&nbsp;
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>Select Payment</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                Choose your preferred payment: Bank transfer, credit card, wire
                transfer or digital options.
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>Secure Verification</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                Verify identity and we confirm your details via our
                Know-Your-Customer (KYC) security procedures.
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block~kx7d2c5bhto',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>Transfer Complete</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  Once verified, you will receive assets into your account
                  rapidly – depending on the payment method.
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper kx7daap44cm-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span style={{background:'#fff', color:'rgb(0, 115, 209)'}}>AVOID DROP-OFFS. ENHANCE CONVERSIONS. ZERO RISK.</span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
};
