import React from 'react';
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: require('../assets/joinus.jpg').default,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title kx7e7tyzles-editor_css',
    children: (
      <span>
        <p> Join The Team</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kx7e7xavg6u-editor_css',
    children: (
      <span>
        <span>
          <p>Creating a more equal financial system.</p>
        </span>
      </span>
    ),
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span style={{background:'#fff', color:'rgb(0, 115, 209)'}}>WHO WE ARE</span>
          </span>
        ),
        className: 'title-h1 kx7e9vbvt5-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>We’re the team engineering the cryptocurrency future.</p>
          </span>
        ),
        className: 'title-content kx7ea29i04-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <p>
                  Finlux is one of the fastest-growing public payments and
                  compliance infrastructure providers for the digital asset
                  industry.&nbsp;
                </p>
                <p>
                  We enable the purchase of digital assets and currencies. Users
                  can make profits from our trading service
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content kx7ea95auj-editor_css',
      },
    ],
  },
};
